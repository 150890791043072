<template>
  <div class="me-3">
    <button class="btn dropdown-toggle list_buttons" type="button" data-bs-toggle="dropdown">
      Indicators
    </button>
    <ul class="dropdown-menu p-2">
      <RecursiveMenuItem :items="allData" :collapsed="collapsed" :checkedItems="checkedItems" :level="0" @update-indicator="updateIndicator" :risk="props.risk"/>
    </ul>
  </div>
</template>

<script setup>
import { ref, reactive, defineEmits, defineProps } from "vue";
import RecursiveMenuItem from './RecursiveMenuItem.vue';
import json from "./JSON/indicator_decomposition_webapp.json";

const props = defineProps({
  risk: String,
});

const allData = ref([]);
const collapsed = reactive({});
const checkedItems = reactive({});

const emit = defineEmits(['update-indicator']);

const updateIndicator = (newValue) => {
  emit('update-indicator', newValue);
};

allData.value = organizeData(json);
initializeItems(allData.value);

function organizeData(data){
  const levels = {};
  const itemsById = {};

  data = data.filter(item => !item.symbol.startsWith('b2_'));

  data.forEach((item) => {
    const pathSegments = item.path.split('/');
    const level = pathSegments.length;

    if (!levels[level]) {
      levels[level] = [];
    }

    // Utilisez le chemin comme identifiant
    item.id = item.path;
    item.children = [];

    levels[level].push(item);
    itemsById[item.id] = item;
  });

  // Créez la hiérarchie des éléments
  for (let level in levels) {
    if (level > 1) {
      levels[level].forEach(item => {
        const parentId = item.path.split('/').slice(0, -1).join('/');
        const parentItem = itemsById[parentId];
        if (parentItem) {
          parentItem.children.push(item);
        }
      });
    }
  }
  return levels[1];
}

function initializeItems(items) {
  items.forEach(item => {
    item.collapsed = item.path.split('/').length > 0;
    item.checked = false;
    collapsed[item.id] = item.collapsed;
    checkedItems[item.id] = item.checked;
    if (item.children) {
      initializeItems(item.children);
    }
  });
}
</script>

<style scoped>
.list_buttons button{
    border: solid 2px #021559;
    padding: 0.3em 0.8em;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    font-weight: bold;
    font-size: 1em;
}

.list_buttons button:hover{
    background-color: rgb(229, 229, 229);
}

.list_buttons button:focus{
    color: white;
    background-color: #021559;
    box-shadow: none;
}

</style>