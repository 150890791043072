<template>
  <div class="home_page content text-center bg-grey">
    <particles />

    <div class="homePresentation">

      <h2 class="subtitle_home mb-5">Statistics, forecasts, ratings and early warnings across 150+ countries by
        <strong>TAC ECONOMICS</strong></h2>
      <h1 class="title_home mb-5">Economic, Financial and <span>Alternative Data</span></h1>
      <!-- <h2 class="subtitle_home mb-5"></h2> -->

      <form class="d-flex mb-5 form_search" role="search" action="/search" method="get">
        <div class="input-group input-group-sm ms-3 search">
          <input type="search" class="form-control" name="q" placeholder="Search indicators, countries...">
          <!-- <button class="btn btn-secondary" type="button"><i class="fa fa-search"></i></button> -->
          <button type="submit"><i class="fa fa-search"></i></button>
        </div>
      </form>

      <div class="row d-flex justify-content-center">
        <div class="col-5">
          <div class="mb-2 title"><b>Popular Search</b></div>
          <a href="/search?q=real gdp" class="badge badge-popular">real gdp</a>
          <a href="/search?q=inflation" class="badge badge-popular">inflation</a>
          <a href="/search?q=unemployment" class="badge badge-popular">unemployment</a>
          <a href="/search?q=unemployment united states" class="badge badge-popular">unemployment united states</a>
          <a href="/search?q=esg" class="badge badge-popular">ESG</a>
          <a href="/search?q=country risk premium" class="badge badge-popular">country risk premium</a>
        </div>
        <div class="col-5">
          <div class="mb-2 title"><b>Trending Timeseries</b></div>
          <a href="/data/EIA/BREPUUS/WLD" class="badge badge-popular">Brent Price</a>
          <a href="/data/FRED/UNRATE/USA" class="badge badge-popular">US Unemployment Rate</a>
          <a href="/data/IFS/FIDR_PA_M/FRA" class="badge badge-popular">Deposit Rates in France</a>
          <a href="/data/LTF/CPIYOY_F/FRA" class="badge badge-popular">Long Term Inflation Forecasts for France</a>
        </div>
      </div>

      <img src="../assets/img/home/graph.png" alt="Image Design Home" class="bgGraph">
      <!-- <img src="../assets/img/home/design.png" alt="Image Design Home" class="bgPoints"> -->

    </div>

    <!-- <div class="container">

      <h2 class="homeInfos__title mt-5 mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, <strong>sed do
          eiusmod</strong></h2>

      <div class="homeInfos">
        <div class="homeInfos__blocs">
          <h2>Analyse</h2>
          <img src="../assets/img/home/analyse.png" alt="">
          <div class="subtitles">
            <h1>123m+</h1>
            <span>Time Series</span>
          </div>
          <button class="learn_more">Learn More</button>
        </div>

        <div class="homeInfos__blocs">
          <h2>Visualise</h2>
          <img src="../assets/img/home/visualise.png" alt="">
          <div class="subtitles">
            <h1>90+</h1>
            <span>Chart types</span>
          </div>
          <button class="learn_more">Learn More</button>
        </div>

        <div class="homeInfos__blocs">
          <h2>Share</h2>
          <img src="../assets/img/home/share.png" alt="">
          <div class="subtitles">
            <h1>900+</h1>
            <span>Connections</span>
          </div>
          <button class="learn_more">Learn More</button>
        </div>
      </div>

    </div> -->

  </div>

  <!-- <div class="graph">
    <img src="../assets/img/graph.png" alt="Description de l'image">
  </div> -->
</template>

<script>

import particles from "../components/particles.vue";

export default {
  components: {
    particles
  }
}
</script>


<style scoped>
.title_home {
  color: white;
  font-weight: 900;
  font-size: 3em;
  position: relative;
  font-family: 'Poppins';
  z-index: 6;
}

.title_home span {
  color: #FF971E;
  display: block;
}

.subtitle_home {
  color: white;
  font-size: 1.2em;
  position: relative;
  z-index: 6;
  font-family: 'Poppins';
}

.homePresentation {
  padding-top: 5%;
  height: 90vh;
  background-image: url("../assets/img/home/bg-blue_reversed.png");
}

form {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 8;
}

.search {
  width: 50%;
}

.search input {
  padding: 0.7em 2em;
  border-radius: 30px;
  font-size: 1.2em;
  transition: all 0.2s ease-in-out;
  border: none;
  color: white;
}

.search input::placeholder {
  transition: all 0.2s ease-in-out;
  font-family: 'Poppins';
}

.search input:hover {
  background-color: rgba(255, 255, 255, 0.891);
}

.search input:focus {
  background-color: rgba(255, 255, 255, 0.27);
}

.search input:hover::placeholder {
  color: black;
}

.search input:focus::placeholder {
  color: white;
}

.search button i {
  font-size: 1.2em;
}

.search button {
  width: 7%;
  border: none;
  background-color: white;
  border-top-right-radius: 30px;
  border-end-end-radius: 30px;
  transition: all 0.2s ease-in-out;
}

.search button:hover {
  background-color: rgba(255, 255, 255, 0.891);
}

.search button:active {
  background-color: rgba(255, 255, 255, 0.891);
}

.title {
  color: white;
  font-size: 1.4em;
  position: relative;
  z-index: 8;
  font-family: 'Poppins';
}

.badge-popular {
  text-decoration: none;
  font-size: 0.9rem;
  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 30px;
  color: white;
  padding: 1em;
  font-weight: 500;
  margin: 0.2rem;
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 8;
  font-family: 'Poppins';
}

.badge-popular:hover {
  opacity: 0.8;
}

.homePresentation {
  position: relative;
}

.homePresentation .bgGraph {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300px;
  z-index: 2;
}

/* ################################################################## */

.homeInfos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 5em;
  font-family: 'Poppins';
}

.homeInfos__title {
  font-family: 'Poppins';
  font-size: 1.4em;
  font-weight: bold;
}

.homeInfos__title strong {
  color: #FF971E;
  font-weight: 900;
}

.homeInfos__blocs {
  width: 30%;
  height: 500px;
  padding: 3em 1em;
  border-radius: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.homeInfos__blocs h2 {
  font-size: 1.8em;
  font-weight: 600;
}

.homeInfos__blocs h1 {
  color: #FF971E;
  font-weight: 600;
}

.subtitles {
  color: #010D3A;
}

.learn_more {
  border: none;
  color: white;
  background-color: #021559;
  padding: 0.5em 1em;
  border-radius: 30px;
  border: solid 2px #021559;
  width: 70%;
  transition: all 0.2s ease-in-out;
}

.learn_more:hover {
  background-color: white;
  color: #021559;
  font-weight: 600;
}

.learn_more:active {
  transition: all 0.1s ease-in-out;
  background-color: #021559;
  color: white;
}

@media screen and (max-width:991px) {
  .homeInfos {
    flex-direction: column;
  }

  .homeInfos__blocs {
    width: 80%;
    margin-bottom: 2em;
  }
}
</style>
