<template>
  <div class="blocDatasets">
    <h2 class="mt-3">Datasets</h2>
    <hr>

    <div class="row row-cols-1 row-cols-md-3">
      <div class="col mb-4" v-for="dataset in datasets" :key="dataset">

        <a v-bind:href="'/search?dataset=' + dataset.id">
          <div class="flip-card">
            <div class="blockDataset card justify-flex-start align-items-center"
              @mouseover="dataset.hovered = true" @mouseleave="dataset.hovered = false">
              <h4 class="pt-3" :class="{ 'card-title': true, 'title-hover': dataset.hovered }">{{ dataset.name }}</h4>
              <hr>
              <p class="card-subtitle" v-if="dataset.id != dataset.provider_code">{{ dataset.provider_name
              }}
              </p>
              <div class="blockDataset__badge w-75 text-center">
                <p class="badge badge-free" v-if="dataset.free === true">free</p>
                <p class="badge badge-premium" v-else>premium</p>
              </div>
              <div class="description" :class="{ 'show': dataset.hovered }">
                <div class="h-100 justify-content-center align-items-center p-2">
                  <p>{{ dataset.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </a>

      </div>
    </div>
  </div>
</template>


<script>
import api from '@/services/api'

export default {
  data() {
    return {
      datasets: []
    }
  },
  methods: {
    async load() {
      await api.get("/data/datasets").then(response => this.datasets = response.data.data)
      const filteredTab = this.datasets.filter(item => item.id !== 'bmt' && item.id !== 'unctad_international_trade' && item.id !== 'dots' && item.id !== 'cepii');
      this.datasets = filteredTab;
    }
  },
  async mounted() {
    await this.load()
  }
}

</script>


<style scoped>
.blocDatasets {
  padding: 0 10%;
}

.blockDataset {
  height: 250px;
  border-radius: 10px;
  padding: 1em;
  transition: all 0.3s ease-in-out;
  border: solid 2px rgb(222, 222, 222);
  box-shadow: 0 1px 3px grey;
}

.blockDataset:hover {
  color: white;
  background-color: #031f85;
  border-color: #031f85;
}

.blockDataset:hover .blockDataset__badge, .blockDataset:hover .card-title, .blockDataset:hover .card-subtitle, .blockDataset:hover hr {
  transform: translateY(-10px);
}

.blockDataset:hover .blockDataset__badge {
  padding: 0 1em;
}

.blockDataset:hover hr {
  border-color: white;
}

.blockDataset:active {
  opacity: 0.7;
  transition: all 0.1s ease-in-out;
}

.blockDataset hr {
  width: 80%;
  border: 1px solid black;
  transition: all 0.2s ease-in-out;
}

.card-title {
  color: var(--te-color-title);
  font-weight: 600;
  font-size: 1.0rem;
  transition: all 0.2s ease-in-out;
}

.card-subtitle {
  transition: all 0.2s ease-in-out;
}

.blockDataset__badge {
  padding: 1em;
  transition: all 0.2s ease-in-out;
}

.title-hover {
  color: white;
}

.description {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  font-size: 0.8em;
  text-align: justify;
}

.show {
  opacity: 1;
  transform: translateY(0);
}
</style>