<template>
  <!-- TODO Fix ajouter une vérification pour désactiver la barre du poids si au moins 50% de ses sous-indicateurs ont un poids de 0 -->
  <div class="d-flex col-4" v-if="isDataCalculated == true">
      <div v-for="item in allData"  :key="item.id" class="col-12 lvl1menu d-flex flex-wrap" :style="'background-color: ' + item.color + ';'">
        
        <div class="col-12 p-2 text-center text-white d-flex flex-wrap justify-content-center align-items-center">
          <p class="mb-0 col-12">{{ item.description }}</p>
          <!-- <p class="mb-0" v-if="item[currentRisk][0]">{{ item[currentRisk][0].value }}</p> -->
          <input type="range" class="form-range me-2" min="0" max="8" id="indicator-range" v-model="item.sliderValue" @input="recalculateValues" :disabled="isDisabled(item)">
          <p class="mb-0" >{{ item.sliderValue }}</p>

        </div>
        
        <div v-for="(child1, index) in item.children" :key="child1.id" class="col-4 lvl2menu d-flex flex-column" :style="'background-color: ' + child1.color + ';'" :class="'lvl2menu-'+index">
          
          <div class="col-12 p-2 text-center text-white d-flex flex-wrap justify-content-center align-items-center">
            <p class="mb-0 col-12">{{ child1.description }}</p>
            <!-- <p class="mb-0" v-if="child1[currentRisk][0]">{{ child1[currentRisk][0].value }}</p> -->
            <input type="range" class="form-range me-2" min="0" max="8" id="indicator-range" v-model="child1.sliderValue" @input="recalculateValues"  :disabled="item.sliderValue == 0 || child1[props.currentRisk] == 0 || isDisabled(child1)">
            <p class="mb-0" >{{ child1.sliderValue }}</p>

          </div>

            <div v-for="(child2, index) in child1.children" :key="child2.id" class="col-12 d-flex flex-wrap flex-column flex-fill lvl3menu" :style="'background-color: ' + child2.color + ';'" :class="'lvl3menu-'+index ">
              <div class="p-2 text-center h-100 d-flex justify-content-center align-items-center flex-wrap position-relative" :class="{'col-12 lvl3-close': child2.children.length == 0 || child2.closed, 'col-6 lvl3-open': child2.children.length > 0}" >
                <button v-if="child2.children.length > 0" @click="toggleParent(child2)" class="toggle-arrow"><img :style="child2.closed ? 'transform: rotate(0deg);' : 'transform: rotate(90deg);'" src="/img/country_profile/fleche.png" alt="" width="10"></button>
                <p class="mb-0 col-12" >{{ child2.description }}</p>
                <!-- <p class="mb-0" v-if="child2[currentRisk][0]">{{ child2[currentRisk][0].value }}</p> -->
                <input type="range" class="form-range" min="0" max="8" id="indicator-range" v-model="child2.sliderValue" @input="recalculateValues" :disabled="child1.sliderValue == 0 || child2[props.currentRisk] == 0 || isDisabled(child2)">
                <p class="mb-0" >{{ child2.sliderValue }}</p>

              </div>
              
              <template v-if="child2.closed == false">
                <div v-for="(child3, index) in child2.children" :key="child3.id" class="col-6 lvl4menu d-flex flex-fill" :class="'lvl4menu-'+index">
                  <div class="p-1 text-center h-100 d-flex justify-content-center align-items-center flex-wrap">
                    <p class="mb-0 col-12">{{ child3.description }}</p>
                    <!-- <p class="mb-0" v-if="child3[currentRisk][0]">{{ child3[currentRisk][0].value }}</p> -->
                    <input type="range" class="form-range me-2" min="0" max="8" id="indicator-range" v-model="child3.sliderValue" @input="recalculateValues" :disabled="child1.sliderValue == 0 || child2.sliderValue == 0 || child3[props.currentRisk] == 0">
                    <p class="mb-0" >{{ child3.sliderValue }}</p>
                  </div>
                </div>
              </template>
            </div>
        </div>
      </div>
  </div>
</template>



<script setup>
import { ref, defineProps, onMounted, watch, defineEmits } from "vue";
import json from "./JSON/indicator_decomposition_webapp.json";
import api from "../services/api.js";

const props = defineProps({
  currentRisk: String,
  risks: Array,
});


const emit = defineEmits(['update-data']);
const colors = ref({
    E:{
      1: "#375623",
      2: "#548235",
      3: "#C6E0B4",
      4: "#E2EFDA",
      5: "#A9D08E",
      6: "#2F75B5",
      7: "#8EA9DB",
      8: "#D9E1F2",
      9: "#C00000",
      10: "#FF7D7D",
      11: "#FFA3A3",
      12: "#FFC5C5",
    }
})
const allData = ref([]);
const isDataLoaded = ref(false);
const isDataCalculated = ref(false);
let openedItem = ref(null);

// Mise à jour les données du tableau lors du changement de la méthode de calcul
watch(() => props.currentRisk, () => {
  isDataCalculated.value = false;
  calculateAllValues(allData.value);
  isDataCalculated.value = true;
});

async function organizeData(data){
  return new Promise((resolve) => {
    const levels = {};
    const itemsById = {};

    // On enlève les éléments brique 2
    data = data.filter(item => !item.symbol.startsWith('b2_'));

    // Créez un objet imbriqué pour chaque élément
    data.forEach((item) => {
      const pathSegments = item.path.split('/');
      const level = pathSegments.length;

      if (!levels[level]) {
        levels[level] = [];
      }
      // Utilisez le chemin comme identifiant
      item.id = item.path;
      item.children = [];
      item.sliderValue = 1;
      if(item.symbol.startsWith('fam_')){
        item.closed = true;
      }
      props.risks.forEach(risk => {
        if (item[risk] === 1) {
          item[risk] = [];
        }
      });

      levels[level].push(item);
      itemsById[item.id] = item;
    });

    // Créez la hiérarchie des éléments
    for (let level in levels) {
      if (level > 1) {
          levels[level].forEach(item => {
            const parentId = item.path.split('/').slice(0, -1).join('/');
            const parentItem = itemsById[parentId];
            if (parentItem) {
                parentItem.children.push(item);
                item.parent = parentItem; 
            }
          });
      }
    }
    allData.value = levels[1];
    resolve();
  });
}
async function getLastIndicators(data, lastIndicators = []) {
   for (const item of data) {
     // Si 'children' est vide
     if (item.children.length === 0) {
       let indicator = { symbol: item.symbol };
       for (const risk of props.risks) {
         if (item[risk] !== undefined) {
           indicator[risk] = item[risk];
         }
       }
       lastIndicators.push(indicator);
     } else {
       // Si l'élément a des enfants, appliquez la fonction récursivement à ses enfants
       await getLastIndicators(item.children, lastIndicators);
     }
   }
   return lastIndicators;
}

async function getMapData(MapData){
  let lastIndicators = await getLastIndicators(MapData);
  for(const indicator of lastIndicators){
      for(const risk of props.risks){
        if(indicator[risk] !== 0){
          const response = await api.get(`data/app_esg/${indicator.symbol}?date=2023-01-01&ratingtype=${risk}`);
          response.data.data.forEach((element) => {
              if(element.value !== null){
                  indicator[risk].push({country_id: element.country_id, value: element.value});
              }
              else{
                indicator[risk].push({country_id: element.country_id, value: null});
              }
            });
        }
      }
  }
  // Parcourir l'objet data
  for (let item of MapData) {
    await assignMapValues(item, lastIndicators);
  }
  isDataLoaded.value = true; 
}

async function assignMapValues(item, lastIndicators) {
  let indicator = lastIndicators.find(ind => ind.symbol === item.symbol);
  if (indicator) {
    // Assigner les valeurs de l'indicateur à l'élément
    for (let riskType of Object.keys(indicator)) {
      if(item[riskType] !== undefined){
        item[riskType] = indicator[riskType];
      }
    } 
  }
  // Parcourir les enfants de l'élément
  if (item.children) {
    for (let child of item.children) {
      await assignMapValues(child, lastIndicators);
    }
  }
}

const calculateAllValues =  (items) => {
  items.forEach(item => {
    if (item.children.length > 0) {
      calculateAllValues(item.children);
    }
    calculateParentValue(item);
  });
  emit('update-data', allData.value);
}

const calculateParentValue = (parent) => {
  // Créer un objet pour stocker les totaux et les comptes pour chaque pays
  let countryTotals = {};

  // Parcourir chaque indicateur enfant
  parent.children.forEach(child => {
    // Vérifier si child[props.currentRisk] est un tableau
    if (Array.isArray(child[props.currentRisk]) && child[props.currentRisk].length > 0) {

      // Parcourir chaque pays dans l'indicateur enfant
      child[props.currentRisk].forEach(country => {
        // Si le pays n'a pas encore été ajouté à countryTotals, l'ajouter
        if (!countryTotals[country.country_id]) {
          countryTotals[country.country_id] = { total: 0, count: 0, indicatorsWithNullValue: 0, totalIndicators: 0, indicatorsWithZeroWeight: 0 };
        }

        // Ajouter la valeur du pays pondérée par le curseur à total et incrémenter le compte
        if (country.value === null || country.value === isNaN) {
          countryTotals[country.country_id].indicatorsWithNullValue++;
        } else {
          countryTotals[country.country_id].total += (country.value * parseInt(child.sliderValue));
          countryTotals[country.country_id].count += parseInt(child.sliderValue);
        }

        // Incrémenter le nombre d'indicateurs avec un poids de 0 si le poids de l'indicateur est 0
        if (parseInt(child.sliderValue) === 0) {
          countryTotals[country.country_id].indicatorsWithZeroWeight++;
        }

        // Incrémenter le nombre total d'indicateurs
        countryTotals[country.country_id].totalIndicators++;
      });
    }
  });

  // Calculer la moyenne pour chaque pays et assigner la valeur à l'indicateur parent
  let countryAverages = [];

  for (let country_id in countryTotals) {
    if (countryTotals[country_id].indicatorsWithNullValue / countryTotals[country_id].totalIndicators > 0.5 || countryTotals[country_id].indicatorsWithZeroWeight / countryTotals[country_id].totalIndicators > 0.5) {
      countryAverages.push({ country_id: country_id, value: null });
    } else {
      let average = countryTotals[country_id].total / countryTotals[country_id].count;
      countryAverages.push({ country_id: country_id, value: average });
    }
  }

  // Si l'indicateur parent a des enfants et que countryAverages contient des valeurs, assigner countryAverages à parent[props.currentRisk]
  if (parent.children.length > 0 && countryAverages.length > 0) {
    parent[props.currentRisk] = countryAverages;
  }
}

const recalculateValues = () => {
  calculateAllValues(allData.value);
};

function addColor(data, index = 1) {
  for (let item of data) {
    if (item.type !== "brique1" && index <= 12) {
      item.color = colors.value.E[index];
      index++;
    }
    if (item.children.length > 0) {
      index = addColor(item.children, index);
    }
  }
  return index;
}

const toggleParent = (item) => {
  // Si l'élément sélectionné est déjà ouvert, le fermer
  if (openedItem.value && openedItem.value.id === item.id) {
    item.closed = true;
    openedItem.value = null;
  } else {
    // Si un autre élément est déjà ouvert, le fermer
    if (openedItem.value) {
      openedItem.value.closed = true;
    }
    // Ouvrir l'élément sélectionné et le stocker comme l'élément ouvert
    item.closed = false;
    openedItem.value = item;
  }
}

const isDisabled = (item) => {
  const totalChildren = item.children.length;
  const zeroWeightChildren = item.children.filter(child => child.sliderValue == 0).length;
  return zeroWeightChildren / totalChildren > 0.5;
}

// Organiser les données au chargement de la page et calculer les valeurs de tous les indicateurs
onMounted(async () => {
  await organizeData(json);
  await getMapData(allData.value)
  calculateAllValues(allData.value);
  addColor(allData.value);
  console.log(allData.value);
  isDataCalculated.value = true;
  emit('update-data', allData.value);
}); 

</script>

<style scoped>
.lvl1menu p{
  font-size: 1.2rem;
}

.lvl2menu p{
  font-size: 0.8rem;
}

.lvl2menu div:first-of-type{
  height: 105px;
}

.lvl3menu p{
  font-size: 0.6rem;
}

.toggle-arrow {
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  top: 0;
  right: 0;
}

/* Style pour afficher la flèche dans la direction appropriée */
.lvl3menu.closed .toggle-arrow img {
  transform: rotate(90deg);
}

.lvl4menu p{
  font-size: 0.6rem;
}

.lvl1menu .form-range {
    width: 175px;
}

.lvl2menu .form-range {
    width: 120px;
}

.lvl3menu .lvl3-open .form-range {
  width: 60px;
}

.lvl3menu .lvl3-close .form-range {
    width: 90px;
}

.lvl4menu .form-range {
    width: 60px;
}


/* Pour Chrome et Safari */
.form-range::-webkit-slider-runnable-track {
    border: 1px solid #000;
    height: 10px
}

/* Pour Firefox */
.form-range::-moz-range-track {
    border: 1px solid #000;
    height: 10px
}

/* Couleurs pour chaque indicateur de niveau 4 */
.lvl2menu-0 .lvl4menu:nth-child(even) {
    background-color: #EBFBED;
}

/* Cible les éléments impairs de niveau 4 */
.lvl2menu-0 .lvl4menu:nth-child(odd) {
    background-color: #C1F1C8;
}

.lvl2menu-1 .lvl3menu-0 .lvl4menu:nth-child(2) {
    background-color: #D6DCE4;
}

.lvl2menu-1 .lvl3menu-0 .lvl4menu:nth-child(3) {
    background-color: #C9F1FF;
}

.lvl2menu-1 .lvl3menu-0 .lvl4menu:nth-child(4) {
    background-color: #C9FBFB;
}

.lvl2menu-1 .lvl3menu-1 .lvl4menu:nth-child(2) {
    background-color: #C9F1FF;
}

.lvl2menu-1 .lvl3menu-1 .lvl4menu:nth-child(3) {
    background-color: #C9FBFB;
}

.disabled-indicator{
  background-color: #808080 !important;
  color: #000000;
}

</style>