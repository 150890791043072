<template>
<div class="d-flex">
    <AsideNavbar/>
    <div class="div-container d-flex flex-wrap justify-content-center ms-auto px-3">
        <section class="col-12">
            <div class="p-3">
                <h2 class="fs-3" >ESG Country Score</h2>
                <hr>
            </div>
            <div class="d-flex list_buttons justify-content-between align-items-center px-3" >
                <div class="col-4 d-flex gap-3">
                    <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Select a country
                    </button>
                    <ul class="dropdown-menu p-1 country_list">
                        <div>
                            <input class="form-control mb-1 p-1" type="text" placeholder="Search country" 
                            v-model="searchTerm" @input="onSearchInput">
                        </div>
                        <div v-for="country in onSearchInput()" :key="country.alpha3" class="p-1 d-flex">
                            <a :href="'/countryProfile/'+country.name" class="w-100">{{ country.name }}</a>
                        </div>
                    </ul>
                    <div class="dropdown">
                        <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Select a risk
                        </button>
                        <ul class="dropdown-menu range p-1">
                            <div v-for="(risk, index) in risks" :key="risk" class="p-1 d-flex">
                                <input @change="updateRisk(risk)" class="me-1" type="radio" :id="index" :value="risk" :checked="risk === risks[0]" name="riskSelection">
                                <label class="form-check-label w-100" :for="index">{{ risk }}</label>
                            </div>
                        </ul>
                    </div>
                </div>
                <div class="col-8 d-flex justify-content-end" >
                    <!-- TODO ajouter fonction d'export de toute la page -->
                    <button class="btn">
                            export to PDF
                    </button>
                </div>
            </div>
        </section>

        <h2 class="text-center fs-2 m-3">{{ country.name }} ESG - {{ currentRisk }}</h2>

        <section class="graph-score my-5 col-12 px-3" >
            <ProgressBar :indicatorE="e" />
        </section>

        <section class="profile d-flex  justify-content-center gap-5 my-4 col-12 px-2">
            <article class="env-profile flex-fill w-100 px-2">
                <h3 class="fw-bold text-center fs-4" >Environmental Profile</h3>
                <hr class="delimiter">
                <div class="d-flex">
                    <p class="w-50">Country Score : </p>
                    <p class="w-50 text-center" >{{ e }}</p>
                </div>
                <hr class="delimiter" >
                <div class="col my-4">
                    <div class="col-12 d-flex justify-content-between align-items-center my-5">
                        <img src="/img/country_profile/climat.png" alt="" class="col-3">
                        <p class="col-5">Global Climate Change</p>
                        <p class="col-3">{{ dim_GCC }}</p>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center my-5">
                        <img src="/img/country_profile/ecosysteme.png" alt="" class="col-3">
                        <p class="col-5">Biodiversirty and natural endowments</p>
                        <p class="col-3">{{ dim_BIO }}</p>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center my-5">
                        <img src="/img/country_profile/usine.png" alt="" class="col-3">
                        <p class="col-5">Industrial Polution</p>
                        <p class="col-3">{{ dim_IND }}</p>
                    </div>
                </div>
            </article>
            <article class="soc-profile flex-fill w-100 px-2">
                <h3 class="fw-bold text-center fs-4" >Social Profile</h3>
                <hr class="delimiter" >
                <p>Country Score :</p>
                <hr class="delimiter" >
                <div class="col my-4">
                    <div class="col-12 d-flex justify-content-between align-items-center my-5">
                        <img src="/img/country_profile/message.png" alt="" class="col-3">
                        <p class="col-5">Social Tensions and Disruptions</p>
                        <p class="col-3"></p>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center my-5">
                        <img src="/img/country_profile/web.png" alt="" class="col-3">
                        <p class="col-5">Social Performances and Vulnerability</p>
                        <p class="col-3"></p>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center my-5">
                        <img src="/img/country_profile/groupe.png" alt="" class="col-3">
                        <p class="col-5">Demographics</p>
                        <p class="col-3"></p>
                    </div>
                </div>   
            </article>
            <article class="gov-profile flex-fill w-100 px-2">
                <h3 class="fw-bold text-center fs-4" >Governance Profile</h3>
                <hr class="delimiter" >
                <p>Country Score :</p>
                <hr class="delimiter" >
                <div class="col my-4">
                    <div class="col-12 d-flex justify-content-between align-items-center my-5">
                        <img src="/img/country_profile/hierarchie.png" alt="" class="col-3">
                        <p class="col-5">Political Stability and Disruptions</p>
                        <p class="col-3"></p>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center my-5">
                        <img src="/img/country_profile/justice.png" alt="" class="col-3">
                        <p class="col-5">Institutions</p>
                        <p class="col-3"></p>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center my-5">
                        <img src="/img/country_profile/livre.png" alt="" class="col-3">
                        <p class="col-5">Legal and Business Environment</p>
                        <p class="col-3"></p>
                    </div>
                </div>    
            </article>
        </section>

        <section class="graphics-score my-4 col-12">
            <div class="d-flex justify-content-evenly gap-5 col">
                <div class="col-3 div-graphics" @click="changeIndicator('Environmental')">
                    <h2 class="text-center" :class="{ 'active': indicator === 'Environmental' }">Environmental</h2>
                    <hr :class="{ 'hr-active': indicator === 'Environmental' }">
                </div>
                <div class="col-3 div-graphics" @click="changeIndicator('Social')">
                    <h2 class="text-center" :class="{ 'active': indicator === 'Social' }">Social</h2>
                    <hr :class="{ 'hr-active': indicator === 'Social' }">
                </div>
                <div class="col-3 div-graphics" @click="changeIndicator('Governance')">
                    <h2 class="text-center" :class="{ 'active': indicator === 'Governance' }">Governance</h2>
                    <hr :class="{ 'hr-active': indicator === 'Governance' }">
                </div>
            </div>
            <!-- TODO Fix graphique radar lorsqu'il n'y a que 1 ou 2 valeurs pour les briques 1 (risque extreme)-->
            <ChartProfile v-if="isCountryDataLoaded && indicator == 'Environmental'" :indicator="e"  :country="country.risks[currentRisk]" :TitleIndicator="indicator"/>
            <ChartProfile v-if="isCountryDataLoaded && indicator == 'Social'" :indicator="s"  :country="country.risks[currentRisk]" :TitleIndicator="indicator"/>
            <ChartProfile v-if="isCountryDataLoaded && indicator == 'Governance'" :indicator="g"  :country="country.risks[currentRisk]" :TitleIndicator="indicator"/>

        </section>
        
        <section class="tab d-flex flex-wrap col-12 px-3" v-if="isCountryDataLoaded">
            <CountryProfileTab :risks="risks" :country="country"/>
        </section>

    </div>
</div>
</template>


<script setup>
import api from '@/services/api'
import { nextTick, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import ProgressBar from '../components/ProgressBar.vue';
import CountryProfileTab from '../components/CountryProfileTab.vue';
import ChartProfile from '../components/ChartProfile.vue';
import AsideNavbar from '../components/AsideNavbar.vue';

let isCountryDataLoaded = ref(false);
const countries = ref([]);
const country = ref({});
const risks = ref(['global', 'reputation', 'materialisation', 'extreme', 'chronique']);
const currentRisk = ref('global');
let indicator = ref('Environmental');
let searchTerm = ref('');

let e = ref(0); // Environmental
let s = ref(0); // Social
let g = ref(0); // Governance

// Variables de dimension
let dim_BIO = ref(0);
let dim_GCC = ref(0);
let dim_IND = ref(0);


watch(currentRisk, () => {
    e.value = getIndicatorValue('E');
    dim_BIO.value = getIndicatorValue('dim_GCC');
    dim_GCC.value = getIndicatorValue('dim_BIO');
    dim_IND.value = getIndicatorValue('dim_IND');
}, { immediate: true });

function getCountry(){
    const route = useRoute();
    country.value.name = route.params.country;
}

async function getCountries(){
    const response = await api.get(`data/app_esg/loadview/global/allcnt?date=2023-01-01&page_name=homepage`);
    const data = response.data;
    const countryObj = {};
    data.data.forEach((country) => {
        if(country.name != null){
            countryObj[country.name] = { name: country.name, alpha3: country.country_id };
        }
    });
    countries.value = Object.values(countryObj);
}

async function addAlpha3(){
    country.value.alpha3 = countries.value.find(item => item.name === country.value.name).alpha3;
}

async function getCountryData(){
    const risksData = {};
    for (const risk of risks.value) {
        const response = await api.get(`data/app_esg/loadview/${risk}/${country.value.alpha3}?date=2023-01-01&page_name=country_profile`);
        risksData[risk] = response.data;
    }
    country.value.risks = risksData;

    e.value = getIndicatorValue('E');
    dim_GCC.value = getIndicatorValue('dim_GCC');
    dim_BIO.value = getIndicatorValue('dim_BIO');
    dim_IND.value = getIndicatorValue('dim_IND');
    await nextTick();
    isCountryDataLoaded.value = true;
}

function getIndicatorValue(symbol){
    if (country.value.risks && country.value.risks[currentRisk.value]) {
        const indicatorData = country.value.risks[currentRisk.value].data.find(item => item.symbol === symbol);
        if (indicatorData) {
            return Math.round(indicatorData.myvalue * 100) / 100;
        }
    }
    return 0;
}

function onSearchInput() {
    return countries.value.filter(country => {
        const search = searchTerm.value ? searchTerm.value.toLowerCase() : '';
        const name = country.name.toLowerCase();
        return name.includes(search);
    });
}

function updateRisk(risk){
    currentRisk.value = risk;
}

function changeIndicator(ind){
    indicator.value = ind;
}

onMounted(async () => {
    getCountry();
    await getCountries();
    await addAlpha3();
    await getCountryData();
});
</script>

<style scoped>

.div-container{
    width: 90vw;
}

.country_list{
    max-height: 350px;
    overflow-x: auto
}

.country_list a{
    color: #212529;
}

.list_buttons button{
    border: solid 2px #021559;
    padding: 0.3em 0.8em;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    font-weight: bold;
    font-size: 1em;
}

.list_buttons button:hover{
    background-color: rgb(229, 229, 229);
}

.list_buttons button:focus{
    color: white;
    background-color: #021559;
    box-shadow: none;
}



.range div:hover{
  background-color: lightgray;
}

.range label:hover, .range input:hover{
  cursor: pointer;
}

.country_list .form-control{
    height: 30px;
    cursor: text;
}

.range input[type="radio"] {
    display: none;
}

.range input[type="radio"] + label::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    position: relative;
    bottom: 5px;
    border-radius: 50%;
    border: 2px solid #021559;
    background-color: #fff;
    vertical-align: bottom;
}

.range input[type="radio"]:checked + label::before {
    background-color: #021559;
}

.profile p{
    margin-bottom: 0;
}

.env-profile img, .soc-profile img, .gov-profile img{
    width: 50px;
    height: 50px;
}

.delimiter{
    height: 3px;
    background-color: black;
    opacity: 1;
}

.active{
    font-weight: bold;
}

.hr-active{
    opacity: 1;
    height: 2px;
}

.div-graphics{
    cursor: pointer;
}

</style>