<template>
  <form class="form FormSearch" action="/search" method="get">
    <input name="q" type="hidden" value="{{q}}">

    <div class="d-flex flex-row justify-content-between filters mt-3">

      <div class="filtersButtons d-flex">
        <div class="p-1">
          <div class="dropdown">
            <button class="dropdown-toggle" type="button" id="periodicityDropdown"
              data-bs-toggle="dropdown">Localisation</button>

              <div class="dropdown-menu p-2" style="width: 650px;" >
                <div class="w-100 d-inline-flex">
                  <ul class="col-6 pe-2">
                    <h4>Countries :</h4>
                    <input class="form-control form-control-sm mb-1" type="text" placeholder="Search country"
                      v-model="searchTerm" @input="onSearchInput">
                    <input type="button" class="uncheck btn btn-sm btn-outline-secondary" @click.prevent="uncheckAllCountries"
                      value="Unselect all">
                    <template v-for="country in filteredCountries" :key="country.id">
                      <li class="checkBox_filters" v-if="country.short_name">
                        <label class="small" :for="country.id">
                          <input :id="country.id" type="checkbox" :value="country.id" v-model="checked.countries"
                            @change="onFilterChange">
                          {{ country.short_name }}
                        </label>
                      </li>
                    </template>
                  </ul>

                  <!-- Filtre par région -->
                  <ul class="col-6">
                    <h4>Regions :</h4>
                    <template v-for="region in filteredRegions" :key="region.id">
                      <li class="checkBox_filters fw-bold" v-if="region.short_name && region.countries.length">
                        <label class="small d-flex flex-wrap" :for="region.id">
                          <div class="d-inline-flex align-items-center w-100">
                            <input :id="region.id" type="checkbox" :value="region.id" v-model="checked.regions" @change="onRegionCheck(region)"
                              style="min-width: 20px; min-height: 20px;">
                            <p class="dropdown">{{ region.short_name }}</p>
                            <button class="dropdown-toggle arrow" @click.stop="toggleRegion(region)" type="button"></button>
                          </div>
                          <ul class="ul_countries_region" v-if="openRegions.some(openRegion => openRegion.id === region.id)">
                            <template v-for="country in filteredCountriesForRegion(region)" :key="country.id">
                              <li class="checkBox_filters fw-normal" v-if="country.short_name">
                                <label class="" :for="country.id">
                                  <input :id="country.id" type="checkbox" :value="country.id" v-model="checked.countries" @change="onFilterChange">
                                  {{ country.short_name }}
                                </label>
                              </li>
                            </template>
                          </ul>
                        </label>
                      </li>
                    </template>
                  </ul>

                </div>
              </div>  
          </div>
        </div>

        <div class="p-1">
          <div class="dropdown">
            <button class=" dropdown-toggle" type="button" id="periodicityDropdown"
              data-bs-toggle="dropdown">Dataset</button>
            <ul class="dropdown-menu p-2">
              <template v-for="dataset in datasets" :key="dataset.id">
                <li class="checkBox_filters"><label class="small" :for="dataset.id"><input :id="dataset.id"
                      type="checkbox" :value="dataset.id" v-model="checked.datasets" @change="onFilterChange"> {{
                        dataset.id }}</label></li>
              </template>
            </ul>
          </div>
        </div>

        <div class="p-1">
          <div class="dropdown">
            <button class="dropdown-toggle" type="button" id="periodicityDropdown"
              data-bs-toggle="dropdown">Frequency</button>
            <ul class="dropdown-menu p-2">
              <template v-for="item in frequencies" :key="item.id">
                <li class="checkBox_filters"><label class="small" :for="item.id"><input :id="item.id" type="checkbox"
                      :value="item.id" v-model="checked.frequencies" @change="onFilterChange"> {{ item.value }}</label>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>

      <div class="free_premium d-flex align-content-center">
        <div class="filter-list p-1">
          <ul class="filter-list m-0 me-2">
            <li>
              <div class="custom-checkbox">
                <input class="me-1" :id="free.id" type="checkbox" :value="free.value" v-model="checked.free"
                  @change="onFilterChange">
                <label :for="free.id">Free</label>
              </div>
            </li>
            <li class="ms-2">
              <div class="custom-checkbox">
                <input class="me-1" :id="premium.id" type="checkbox" :value="premium.value" v-model="checked.premium"
                  @change="onFilterChange">
                <label :for="premium.id">Premium</label>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </form>

  <div>
    <div class="search-result mb-2">Results : {{ total }} series found <span v-if="term">for "{{ term }}"</span><span
        v-if="dataset">for dataset "{{ dataset }}"</span></div>
    <SeriesList :items="items" @sort="sortItems"/>
    <div class="pagination_bottom d-flex flex-row-reverse bd-highlight">
      <SeriesListPagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage"
        @pagechanged="onPageChange" />
    </div>
  </div>
</template>
  
  
<script>
import api from '@/services/api'
import SeriesList from '@/components/SeriesList.vue'
import SeriesListPagination from '@/components/SeriesListPagination.vue'
import csv from '../assets/csv/regions.csv'

export default {
  components: { SeriesList, SeriesListPagination },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalPages: 1,
      total: "",
      term: "",
      items: [],
      free: { id: "Free", value: true },
      datasets: [],
      countries: [],
      regions: [],
      openRegions: [],
      frequencies: [],
      frequeenciesMap: [
        {id: "A", value: "Annual"},
        {id: "H", value: "Half-yearly"},
        {id: "Q", value: "Quarterly"},
        {id: "M", value: "Monthly"},
        {id: "W", value: "Weekly"},
        {id: "D", value: "Daily"}
      ],
      premium: { id: "Premium", value: true },
      checked: {
        free: true,
        premium: true,
        datasets: [],
        countries: [],
        regions: [],
        frequencies: [] //["A","Q","M","W","D"],
      },
      searchTerm: '',
      error: null,
      sortField: '', // Champ de tri actuel (date ou fréquence)
      sortOrder: 'asc', // Ordre de tri actuel (ascendant ou descendant)
    }
  },
  methods: {
    async load() {
      // await api.get("/data/regions/REG_MENA").then(response => this.countries = response.data.data)

      // Récupération des données des régions  via le fichier CSV
      const data_csv = csv;
      let regions = {};

      for(let i = 0; i < data_csv.length; i++){
        let regionId = data_csv[i].reg_id;
        let countryId = data_csv[i].iso3;

        // Si la région n'existe pas, on la crée
        if(!regions[regionId]){
          regions[regionId] = {
            id: regionId,
            short_name: data_csv[i].reg_name,
            countries: [],
            checked: false
          };
        }
        
        // On ajoute le ou les pays à la région
        regions[regionId].countries.push({
          id: countryId,
          short_name: data_csv[i].country_name,
          checked: false
        });
      }
      this.regions = Object.values(regions);

      // build url
      let urlParams = new URLSearchParams(window.location.search);
      this.term = urlParams.get('q');
      this.dataset = urlParams.get('dataset');

      let url = "/data/search?page=" + this.currentPage + "&per_page=" + this.perPage;
      if (this.term) url = url + "&q=" + this.term;

      if (this.country) {
        url = url + "&country=" + this.country;
      }else {
        for (var d = 0; d < this.checked.countries.length; d++) {
          url = url + "&country=" + this.checked.countries[d];
        }
      }

      if (this.dataset) {
        url = url + "&dataset=" + this.dataset;
      } else {
        for (var k = 0; k < this.checked.datasets.length; k++) {
          url = url + "&dataset=" + this.checked.datasets[k];
        }
      }

      url = url + "&free=" + Number(this.checked.free == 1);
      url = url + "&premium=" + Number(this.checked.premium == 1);
      for (var f = 0; f < this.checked.frequencies.length; f++) {
        url = url + "&frequency=" + this.checked.frequencies[f];
      }

      if(this.sortField && this.sortOrder) {
        url = url + "&sort_by=" + this.sortField + "&sort_type=" + this.sortOrder;
      }
      
      await api.get(url).then(response => {
        this.error = null;
        this.items = response.data.data
        this.total = response.data.total
        if (this.total > 0) {
          for (var k = 0; k < this.items.length; k++) {
            this.items[k].url = "/data/" + this.items[k].id;
          }
          this.totalPages = Math.ceil(this.total / this.perPage);
          if (this.totalPages > 500) this.totalPages = 500;
        }
        if (this.datasets.length == 0) {
          this.datasets = response.data.datasets
          //this.checked.datasets = [...new Set(this.datasets.map((item) => item.id))];
          // this.datasets = [...new Set(this.items.map((item) => item.dataset_id))];
          // this.checked.datasets = this.datasets;
        }
        if (this.countries.length == 0) {
          this.countries = response.data.countries
        }

        if(this.frequencies.length == 0){
          this.frequencies = response.data.frequencies.map(frequency => {
            return {
              id: frequency.id,
              value: this.frequeenciesMap.find(f => f.id === frequency.id).value
            };
          });
        }

        for (let i = 0; i < this.items.length; i++) {
          const element = this.items[i];
          if (element.min_date != undefined) {
            const minDate = element.min_date.substring(0, 10);
            const maxDate = element.max_date.substring(0, 10);
            element.min_date = minDate;
            element.max_date = maxDate;
          }
        }
      })
        .catch((error) => {
          this.error = error.response.status
        });

      if (this.error != null) {
        this.items = null;
      }

      console.log(url)

      // this.found = this.items.length
      //this.checked.free = true;
      //this.checked.premium = true;
      //this.checked.frequencies = ["A","Q","M","D"];
      //this.adjustements = [...new Set(this.items.map((item) => item.adjustement))];
      //this.checked.units = this.units;

      //
      //console.log(this.totalPages);
    },
    onPageChange(page) {
      this.currentPage = page;
      this.load();
      //console.log(page);
    },
    onFilterChange() {
      this.currentPage = 1;
      this.load();
    },
    sortItems(column) {
      if (this.sortField === column) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = column;
        this.sortOrder = 'asc';
      }
      this.load();
    },
    onSearchInput() {
      return this.countries.filter(country => {
        const search = this.searchTerm ? this.searchTerm.toLowerCase() : '';
        const name = country.short_name.toLowerCase();
        return name.includes(search);
      });
    },

    // Fonction pour gérer le filtrage par région
    onRegionCheck(region) {
    if (this.checked.regions.includes(region.id)) {
      // If the region is checked, add all its countries to the checked countries
      let newCheckedCountries = [...this.checked.countries];

      region.countries.forEach(country => {
        if (!newCheckedCountries.includes(country.id)) {
          newCheckedCountries.push(country.id);
        }
      });
      this.checked.countries = newCheckedCountries;
    } else {
      // If the region is unchecked, remove all its countries from the checked countries
      this.checked.countries = this.checked.countries.filter(countryId => {
        return !region.countries.some(country => country.id === countryId);
      });
    }
    this.onFilterChange(); // Update la liste des résultats
  },

    // Fonction pour décocher tous les pays et régions
    uncheckAllCountries() {
      this.checked.countries = [];
      this.checked.regions = [];
      this.onFilterChange();
    },

    // Fonction pour afficher ou masquer les pays d'une région
    toggleRegion(region) {
      // Vérifier si la région est déjà ouverte
      const index = this.openRegions.findIndex(openRegion => openRegion.id === region.id);
      if (index !== -1) {
        // Si la région est déjà ouverte, la fermer
        this.openRegions.splice(index, 1);
      } else {
        // Sinon, l'ouvrir
        this.openRegions.push(region);
      }
    },

    // Fonction pour filtrer les pays par région et les afficher ou non en fonction de la recherche
    filteredCountriesForRegion(region) {
    if (this.countries) {
      let regionCountries = region.countries.map(country => country.short_name);
      return this.countries.filter(country => {
        return regionCountries.includes(country.short_name) && country.short_name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1;
      });
    }
    return [];
  }
  },
  computed: {
    //selectedItems: function () {
    //   this.load();
    //   let filteredItems = this.items;
    // filteredItems = filteredItems.filter(function (item) {return this.checked.free.includes(item.free);}, this);
    // filteredItems = filteredItems.filter(function (item) {return this.checked.datasets.includes(item.dataset_id);}, this);
    // filteredItems = filteredItems.filter(function (item) {return this.checked.frequencies.includes(item.frequency);}, this);
    // filteredItems = filteredItems.filter(function (item) {return this.adjustements.includes(item.adjustement);}, this);
    //   return filteredItems;
    //},
    filteredCountries() {
    if (this.countries) {
      return this.countries.filter(country => {
        return country.short_name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1;
      });
    }
    return [];
  },

  // Fonction pour afficher ou non les régions en fonction de la recherche
  filteredRegions() {
    if (this.regions) {
      return this.regions.filter(region => {
        let regionCountries = this.filteredCountriesForRegion(region);
        return regionCountries.length > 0;
      });
    }
    return [];
  }
  },
  mounted() {
    this.load();
  },
}

</script>


<style scoped>
.FormSearch,
.search-result,
.pagination_bottom {
  padding: 0 10%;
}

.search-result {
  font-weight: 600;
  font-size: 1.3em;
  margin: 1em 0 !important;
}

.filters {
  font-size: 0.8rem;
  padding: 0.5em;
  border-radius: 5px;
  border: solid 1px #021559;
}

.filter-list,
.filter-list label {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styles pour l'input checkbox */
.custom-checkbox input[type="checkbox"] {
  display: none;
  /* Masquer l'input d'origine */
}

/* Styles pour la coche personnalisée */
.custom-checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 1.3em;
  font-weight: bold;
  color: #021559;
}

.custom-checkbox label:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 2px;
  width: 18px;
  height: 18px;
  border: 2px solid #021559;
  background-color: #fff;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

/* Styles pour la bordure extérieure */
.custom-checkbox label:before {
  border-color: #021559;
}

/* Styles pour la bordure intérieure */
.custom-checkbox label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 7px;
  width: 8px;
  height: 8px;
  background-color: #fff;
  /* Couleur de la bordure intérieure */
  border: 1px solid #021559;
  /* Couleur de la bordure extérieure */
  border-radius: 5px;
}

.custom-checkbox input[type="checkbox"]:checked+label:before {
  background-color: #021559;
}

.custom-checkbox input[type="checkbox"]:checked+label:after {
  background-color: #fff;
}

ul {
  list-style-type: none;
  padding: 0;
}

.ul_countries_region{
  margin: 8px 0 0 20px;
}

/* pagination */
.pagination-container {
  display: flex;
  column-gap: 10px;
}

.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgb(217, 217, 217);
  color: black;
}

.paginate-buttons:hover {
  background-color: #d8d8d8;
}

.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}

.active-page:hover {
  background-color: #2988c8;
}

.dropdown-menu {
  max-height: 400px;
  width: 250px;
  overflow-y: auto;
  box-shadow: 0 2px 5px rgb(153, 153, 153);
}


/* DropDown Buttons */
.dropdown button {
  border: solid 2px #021559;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  font-weight: bold;
  font-size: 1.2em;
  background-color: rgb(255, 255, 255);
}



.dropdown button:hover {
  background-color: rgb(229, 229, 229);
}

.dropdown button:focus {
  background-color: #021559;
  color: white;
}

.dropdown .arrow{
  border: none;
  padding: 0px 6px;
  margin: 0;
  font-size: 1em;
  vertical-align: center;
}

.dropdown-menu h4{
  font-weight: 800;
}

.panel.panel-primary {
  max-height: 60vh;
  overflow-y: auto;
}

.uncheck {
  width: 100%;
  margin: 0.5em 0;
}
.checkBox_filters p{
  margin: auto 5px auto 0;
}

.checkBox_filters label {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.5em;
}

.checkBox_filters input[type="checkbox"] {
  appearance: none;
  /* Désactiver l'apparence par défaut du navigateur */
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #021559;
  border-radius: 3px;
  margin-right: 5px;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
}



.checkBox_filters input[type="checkbox"]:checked {
  background-color: #021559;
}

.checkBox_filters input[type="checkbox"]:focus {
  outline: none;
  /* Supprimer l'outline par défaut lorsqu'il est focus */
}

.checkBox_filters input[type="checkbox"]:checked::before {
  content: "";
  /* Afficher le symbole de coche Unicode */
  display: inline-block;
  width: 16px;
  height: 16px;
  /* line-height: 16px; */
  text-align: center;
  font-size: 12px;
  color: #fff;
  transition: color 0.3s ease-in-out;
}


</style>