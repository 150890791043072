<template>
    <div class="res-search">
        <div>
            <table id="tableComponent" class="table table-hover table-borderless border">
                <thead>
                    <tr>
                        <th v-for="(header, index) in headers" :key="header.value" @click="index < headers.length - 2 ? $emit('sort', header.value) : null" >
                            {{ header.text }} <i v-if="index < headers.length - 2" class="fa-solid fa-sort"></i>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template  v-for="(item, index) in items" :key="item.id" >
                        <tr @mousemove="updateMessagePosition" @mouseenter="showMessage" @mouseleave="hideMessage"
                        :class="{ restricted: item.as_access === 0 }">
                            <td v-for="header in headers" :key="header.value" class="align-middle">
                                <template v-if="header.value === 'name' && item.as_access === 1">
                                    <a :href="item.url" target="_blank">{{ item.name }}</a>
                                </template>
                                <template v-else-if="header.value === 'free'">
                                    <div v-if="item.as_access === 1" class="badge badge-free">free</div>
                                    <div v-else class="badge badge-premium">premium</div>
                                </template>
                                <template v-else-if="header.value === 'preview'">
                                    <i class="accordion p-1" @click="toggleActive(index)" :class="{ active: isActive[index], 'fa-solid fa-chevron-down': !isActive[index], 'fa-solid fa-chevron-right': isActive[index] }"></i>
                                </template>
                                <template v-else>
                                    {{ item[header.value] }}
                                </template>
                            </td>
                            
                        </tr>
                        <transition name="slide-fade">
                            <tr class="panel" v-if="isActive[index]">
                                <td :colspan="headers.length" class="px-0 py-3">
                                    <div class="content-preview d-flex justify-content-evenly align-items-center">
                                        <ul class="col-5 m-2 ps-2" >
                                            <li class="p-2"><b>Name : </b>{{ item.name }} </li>
                                            <li class="p-2" ><b>Description : </b>{{ item.description }}</li>
                                            <li class="p-2" ><b>API code : </b> {{ item.id }}</li>
                                            <li class="p-2" ><b>Dataset description : </b>{{ item.dataset_description }}</li>
                                            <li class="p-2"><b>Unit : </b>{{ item.unit }}</li>
                                        </ul>
                                        <ChartPreview :chart-id="`chart-${index}`" :url="item.id"/>
                                    </div>
                                </td>
                            </tr>
                        </transition>
                    </template>
                </tbody>
            </table>

            <div v-if="isMessageVisible" class="message" :style="{ top: mouseY + 'px', left: mouseX + 'px' }"
                @mouseenter="cancelHideMessage">
                {{ message }}
            </div>
        </div>
    </div>
</template>

<script>
import ChartPreview from "@/components/ChartPreview.vue";

export default {
    props: { items: Object },
    components:{
        ChartPreview
    },
    data() {
        return {
            headers: [
                { text: "NAME", value: "name", sortable: true },
                { text: "COUNTRY", value: "country_short_name", sortable: true },
                { text: "DATASET", value: "dataset_name", sortable: true },
                { text: "PROVIDER", value: "provider_name", sortable: true },
                { text: "START DATE", value: "min_date", sortable: true },
                { text: "END DATE", value: "max_date", sortable: true },
                { text: "FREQUENCY", value: "frequency", sortable: true },
                { text: "UNIT", value: "unit", sortable: true },
                { text: "FREE", value: "free", sortable: false },
                { text: "PREVIEW", value: "preview", sortable: false }
            ],
            isMessageVisible: false,
            mouseX: 0,
            mouseY: 0,
            message: "No subscription to this dataset. Please contact the support for further informations.",
            messageItem: null,
            isActive: [],
            activeIndex: null,
            filteredData: [],
        }
    },
    methods: {
        showMessage(event, item) {
            if (event.target.classList.contains("restricted")) {
                this.isMessageVisible = true;
                this.updateMessagePosition(event);
                this.messageItem = item;
            }
        },
        hideMessage() {
            this.isMessageVisible = false;
        },
        updateMessagePosition(event) {
            this.mouseX = event.clientX;
            this.mouseY = event.clientY;
        },
        cancelHideMessage() {
            clearTimeout(this.messageTimeout);
        },
        toggleActive(index) {
            // If the element is already active, set activeIndex to null and set isActive[index] to false
            if (this.activeIndex === index) {
                this.isActive[index] = false;
                this.activeIndex = null;
                return;
            }
            // If there was another active element, set its isActive to false
            if (this.activeIndex !== null) {
                this.isActive[this.activeIndex] = false;
            }
            // Otherwise, set activeIndex to the current index and set isActive[index] to true
            this.activeIndex = index;
            this.isActive[index] = true;
        },
    },
    mounted(){
        this.isActive = new Array(this.items.length).fill(false);
    }
}
</script>

<style scoped>

.res-search{
    padding: 0 10%;
}

.table-responsive {
    padding: 0 10%;
}
.table {
    font-size: 0.75rem !important;
    table-layout: fixed
}

tr {
    border-bottom: 1px solid #f0f0f0 !important;
}

thead tr th {
    width: min-content;
}

thead tr th:first-child {
    width: 30%;
}

thead tr th:hover {
    transition: all 0.2s ease-in-out;
    background-color: rgb(234, 234, 234);
    cursor: pointer;
}

thead tr th:active {
    background-color: rgb(215, 215, 215);
}

.restricted {
    background-color: #b6b6b682;
    transition: all 0.2s ease-in-out;
    cursor: not-allowed;
}

.restricted:hover {
    background-color: #80808082;
}

.message {
    position: fixed;
    background-color: #ffbcbc;
    border-radius: 5px;
    border: 2px solid red;
    font-size: 0.9em;
    padding: 0.2em 0.7em;
    left: 0;
    transform: translateX(3%);
    pointer-events: none;
}

.accordion {
  cursor: pointer;
  font-size: large;
}

.content-preview {
    font-size: 14px;
}

.content-preview ul{
    list-style: none;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
.slide-fade-enter-to, .slide-fade-leave {
  transform: translateY(0);
  opacity: 1;
}

</style>