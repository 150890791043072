<template>
    <div :id="chartId" class="col-6 chartDiv"></div>
</template>

<script>
    import api from '@/services/api'
    import * as am4core from "@amcharts/amcharts4/core"
    import * as am4charts from "@amcharts/amcharts4/charts"
    import am4themes_animated from "@amcharts/amcharts4/themes/animated"

    am4core.useTheme(am4themes_animated);

    export default{
        props: {
            url: String, color : { type:String, default: '#CC3517' }, chartId: String
        },
        data() {
            return {
                series : {},
                start_date: '',
                end_date: '',
                optionCollapse: 'Q',
                optionModCollapse: '',
                optionTransform: '',
                codeAccess: '',
            }
        },
        methods: {
            async renderChart(id){
                // Construction URL
                let url = '/data/'+this.url;
                // url += '?start_date='+this.start_date.toISOString().split('T')[0];
                if(this.optionCollapse != ''){
                    url += '?collapse='+this.optionCollapse;
                }
                if(this.optionModCollapse != ''){
                    url += '&mod_collapse='+this.optionModCollapse;
                }
                if(this.optionTransform != ''){
                    url += '&transform='+this.optionTransform;
                }   

                // Requête sur l'API
                await api.get(url)
                    .then(response => this.series = response.data)
                    .catch(error => this.codeAccess = error.response.status);


                if(this.codeAccess != 401){
                    let chart = am4core.create(id, am4charts.XYChart);

                    chart.hiddenState.properties.opacity = 0;
                    chart.data = this.series.data;

                    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                    dateAxis.renderer.grid.template.location = 0;
                    dateAxis.tooltipDateFormat = "yyyy-MM-dd";

                    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis.tooltip.disabled = true;
                    valueAxis.renderer.minWidth = 25;

                    let series = chart.series.push(new am4charts.LineSeries());
                    series.dataFields.dateX = "timestamp";
                    series.dataFields.valueY = "value";
                    series.fill = am4core.color(this.color);
                    series.stroke = am4core.color(this.color);
                    series.strokeWidth = 2;

                }
                
            }
        },
        mounted() {
            this.renderChart(this.chartId);
        },
    }

</script>

<style scoped>
    .chartDiv {
        height: 300px;
        z-index: 999;
    }   
</style>