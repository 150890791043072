<template>
<div class="d-flex">
    <AsideNavbar/>

    <div class="div-container d-flex flex-wrap justify-content-start ms-auto px-3">
        <section class="col-12">
            <div class="p-3">
                <h2 class="fs-3" >Country Indicator</h2>
                <hr>
            </div>

            <div class="d-flex justify-content-start px-3 list_buttons">
                <div class="dropdown">
                    <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Select a risk
                    </button>
                    <ul class="dropdown-menu range p-1">
                        <div v-for="(risk, index) in risks" :key="risk" class="p-1 d-flex">
                            <input @change="updateRisk(risk)" class="me-1 form-check-input" type="radio" :id="index" :value="risk" :checked="risk === risks[0]" name="riskSelection">
                            <label class="form-check-label w-100" :for="index">{{ risk }}</label>
                        </div>
                    </ul>
                </div>
            </div>
        </section>

        <section class="my-3 col-12 px-3" v-if="isDataLoaded">
          <div class="maps-section ">
              <CountryMapIndicator 
                :data="allData"
                :risk="currentRisk" />
          </div>
        </section>

        <section class="px-3 my-3 col-12">
            <CountryIndicatorTab :currentRisk="currentRisk" :risks="risks" @update-data="updateAllData"/>
        </section>
    </div>
</div>
</template>


<script setup>
import CountryIndicatorTab from '../components/CountryIndicatorTab.vue';
import { ref } from 'vue';
import AsideNavbar from '../components/AsideNavbar.vue';
import CountryMapIndicator from '../components/CountryMapIndicator.vue';

const risks = ref(['global', 'reputation', 'materialisation', 'extreme', 'chronique']);
const currentRisk = ref('global');
const allData = ref([]);
const isDataLoaded = ref(false);

function updateRisk(risk){
    currentRisk.value = risk;
}

function updateAllData(data){
    allData.value = data[0];
    isDataLoaded.value = true;
}

</script>

<style scoped>

.div-container{
    width: 90vw;
}


.list_buttons button{
    border: solid 2px #021559;
    padding: 0.3em 0.8em;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    font-weight: bold;
    font-size: 1em;
}

.list_buttons button:hover{
    background-color: rgb(229, 229, 229);
}

.list_buttons button:focus{
    color: white;
    background-color: #021559;
    box-shadow: none;
}

.range input[type="radio"] {
    display: none;
}

.range input[type="radio"] + label::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    position: relative;
    bottom: 5px;
    border-radius: 50%;
    border: 2px solid #021559;
    background-color: #fff;
    vertical-align: bottom;
}

.range input[type="radio"]:checked + label::before {
    background-color: #021559;
}


.range div:hover{
  background-color: lightgray;
}

.range label:hover, .range input:hover{
  cursor: pointer;
}


</style>