<template>

  <div v-if="id === ''">
    <DashBoardList />
  </div>
  <div v-else>
    <DashBoard :id="id" />
  </div>

</template>
  

<script>
import DashBoard from '@/components/DashBoard.vue'
import DashBoardList from '@/components/DashBoardList.vue'

export default {
  props: { id: { type: String } },
  components: {
    DashBoard, DashBoardList
  }
}
</script>
