<template>
    <div class="footer text-center">&copy; 2023 by TAC ECONOMICS.</div>
    <br />
</template>

<style scoped>
.footer {
  font-size: 0.8rem;
  color: #999;
}
</style>
